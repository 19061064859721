import { isNil } from '@/shared/utils';

/**
 * @param {Object} address
 * @return {String}
 */
export const addressFormatter = (address) => {
	if (isNil(address)) return;
	const { street, number, city } = address;
	const s = [street, number, city].filter((n) => n).join(', ');
	return `${s}`;
};

/**
 * @param {String} date
 * @return {String}
 */
export const dateFormat = (date) => {
	const t = date.split('T')[0];
	return [t.substring(8), t.substring(5, 7)].join('/');
};

/**
 * @param {Object} user
 * @return {String}
 */
export const userName = (user) => {
	return [user.firstName, user.name].join(' ');
};
